import styles from './SharingContainer.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { getActiveSalon } from '../../../Auth/store/selectors';
import useWindowSize from '../../../../utils/useWindowSize';
import clx from 'classnames';
import TitleSectionBack from '../../../Common/TitleSectionBack';
import { ReactComponent as SharingIcon } from '../../../../assets/svg/icons/condividi.svg';
import { modalActions } from '../../../Modals/store';

const SharingContainer = () => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const activeSalon = useSelector(getActiveSalon());

  const copyLinkToClickBoard = async (link) => {
    await navigator.clipboard.writeText(link);
    return dispatch(
      modalActions.OPEN_MODAL.SUCCESS({
        type: 'successModal',
        props: {
          header: 'Il tuo link è stato copiato',
        },
      }),
    );
  };
  async function downloadImage(imageSrc, nameOfDownload) {
    console.log(imageSrc);
    const response = await fetch(imageSrc);

    const blobImage = await response.blob();

    const href = URL.createObjectURL(blobImage);

    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    anchorElement.download = nameOfDownload;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  }

  return (
    <div
      className={clx(styles.sharing_container, {
        [styles.sharing_container_mobile]: isMobile,
      })}
    >
      <div className={styles.header}>
        <TitleSectionBack
          title={'Condivisione'}
          description={
            'Condividi il link del tuo salone, Genera un Qr code o condividi semplicemente le tue poltrone dove vuoi'
          }
        />
      </div>
      <div className={styles.sharing_container_list}>
        <div className={styles.sharing_item}>
          <div className={styles.item_title}>Scarica QR Code</div>
          <button
            style={{ border: 'none', backgroundColor: 'transparent' }}
            onClick={() => {
              dispatch(
                modalActions.OPEN_MODAL.SUCCESS({
                  type: 'qrCodeModal',
                  props: { activeSalon, downloadImage },
                }),
              );
            }}
          >
            <SharingIcon />
          </button>
        </div>
        <div className={styles.sharing_item}>
          <div className={styles.item_title}>Condividi Link Salone</div>
          <SharingIcon
            onClick={() =>
              copyLinkToClickBoard(
                `https://application.zetabarber.it/barber/${activeSalon.username}`,
              )
            }
          />
        </div>
        {!(<div className={styles.sharing_item}>
          <div className={styles.item_title}>Condividi Link Poltrona</div>
          <SharingIcon
            onClick={() =>
              dispatch(
                modalActions.OPEN_MODAL.SUCCESS({
                  type: 'sharedWorkersModal',
                  callback: (value) => copyLinkToClickBoard(value),
                }),
              )
            }
          />
        </div>)}
      </div>
    </div>
  );
};

export default SharingContainer;
