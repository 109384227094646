import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { modalActions } from '../../../Modals/store';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SchedulerTask.module.scss';
import useBlockSize from '../../../../utils/useBlockSize';
import clx from 'classnames';
import { ReactComponent as ArticoliIcon } from '../../../../assets/svg/icons/articoli.svg';
import { ReactComponent as BarberPoleIcon } from '../../../../assets/svg/icons/barber_pole.svg';
import { ReactComponent as HorizontalEllipsisIcon } from '../../../../assets/svg/icons/horizontal-ellipsis.svg';
import { ReactComponent as NewClientIcon } from '../../../../assets/svg/icons/nuovo_cliente.svg';

import useWindowSize from '../../../../utils/useWindowSize';
import clsx from 'clsx';
import { badgetList, selectBadges } from '../../constants';
import { getTaskById } from '../../store/selectors';
import { Badgets } from '../../../Common/Badgets';
import { ReactComponent as BadIcon } from 'assets/svg/icons/warning_user.svg';
import { allBadClients } from 'Containers/Clients/store/selectors';

const SchedulerTask = ({ selectedTask, day, setStartData, workerId, isDragStart, stepSize }) => {
  const badClients = useSelector(allBadClients());  
  const dispatch = useDispatch();
  const observedDiv = useRef(null);
  const { isMobile } = useWindowSize();

  const { width: sizeValueWidth, height: sizeValue } = useBlockSize({
    observedBlock: observedDiv,
    sizes: [
      { from: 0, to: 60, value: 1 },
      { from: 60, to: 195, value: 2 },
      { from: 195, to: 300, value: 3 },
      { from: 300, to: 610, value: 4 },
      { from: 610, to: Infinity, value: 5 },
    ],
  });

  const iconSize =
    selectedTask.unixSize < 700 ? Math.min(stepSize, 12) + 5 : Math.min(stepSize, 12) + 10;

  return (
    <>
      {selectedTask.limitCard ? (
        <div
          className={styles.limit_card}
          style={{
            top: `${selectedTask.top}px`,
            height: `${selectedTask.size}px`,
          }}
        ></div>
      ) : (
        <div>
          {!isDragStart ? (
            <div
              className={'card'}
              style={{
                position: 'absolute',
                top: `${selectedTask.top}px`,
                // left: `${selectedTask.left * 10 + 10}px`,
                left: '1%',
                zIndex: selectedTask.hovered ? '-1' : `${selectedTask.left + 100}`,
                width: `calc(95% - ${selectedTask.left * 10 + 10}px)`,
                height: `${selectedTask.size}px`,
                cursor: selectedTask.isActive ? 'grab' : 'pointer',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                backgroundColor: 'transparent',
              }}
              onDragStart={(e) => {
                if (e.target.className === 'card' && selectedTask.isActive) {
                  setStartData({ type: 'dragged', workerId, task: selectedTask, day });
                }
              }}
              draggable={selectedTask.isActive}
              onClick={() => {
                dispatch(
                  modalActions.OPEN_MODAL.SUCCESS({
                    type: 'taskInfoModal',
                    props: {
                      day,
                      workerId,
                      task: selectedTask,
                      isBadClient: badClients?.find(a=> a?.utente === selectedTask.utente),
                    },
                  }),
                );
              }}
            >
              <div
                className={'cardBottom'}
                onDragStart={(e) => {
                  if (e.target.className === 'cardBottom' && selectedTask.isActive)
                    setStartData({
                      type: 'resize',
                      workerId,
                      task: selectedTask,
                      day,
                    });
                }}
                draggable={selectedTask.isActive}
                style={{
                  width: '100%',
                  height: '10px',
                  cursor: selectedTask.isActive ? 'row-resize' : 'pointer',
                }}
              ></div>
            </div>
          ) : null}
          {selectedTask.hovered ? (
            <div
              className={styles.create_card_container}
              style={{
                top: `${selectedTask.top}px`,
                height: `${selectedTask.size - 5}px`,
                background: selectedTask.color,
              }}
            >
              <div className={styles.service_extra_row}>
                <div className={styles.time_in_service_column}>
                  {dayjs(selectedTask.orario_start, 'HH:mm:ss').format('HH:mm')} -{' '}
                  {dayjs(selectedTask.orario_end, 'HH:mm:ss').format('HH:mm')}
                </div>
                <div className={styles.worker_name_in_service_column}>{selectedTask.nome}</div>
              </div>
            </div>
          ) : (
            <>
              <div
                className={clsx(styles.card_container, {
                  [styles.card_container_mobile]: isMobile,
                })}
                ref={observedDiv}
                style={{
                  top: `${selectedTask.top}px`,
                  //left: selectedTask.dragged ? 0 : `${selectedTask.left * 10 + 10}px`,
                  left: '1%',
                  zIndex: selectedTask.dragged ? '-1' : `${selectedTask.left - 100}`,
                  width: !selectedTask.dragged
                    ? '98%'
                    : `calc(100% - ${selectedTask.left * 10 + 10}px)`,
                  height: `${selectedTask.size - 5}px`,
                  background: selectedTask.color,
                  opacity: selectedTask.dragged
                    ? '0.8'
                    : selectedTask.matched
                    ? 1
                    : !selectedTask.isActive
                    ? '0.5'
                    : selectedTask.opacity || 1,
                }}
              >
                <div
                  className={clx(styles.card_wrapper, {
                    [styles.cardSize0]: stepSize < 14 && sizeValue == 1,
                    [styles.cardSize1]: sizeValue === 1,
                    [styles.cardSize2]: sizeValue === 2,
                    [styles.cardSize3]: sizeValue === 3,
                    [styles.cardSize4]: sizeValue === 4,
                    [styles.cardSize5]: sizeValue === 5,
                  })}
                >
                  <div className={styles.service}>
                    <div className={styles.service_box}>
                      <div className={styles.service_box_info}>
                        {selectedTask.servizi.map((service, index) => (
                          <div key={index} className={styles.service_box_info_name}>
                            {service.nome}
                          </div>
                        ))}
                      </div>
                      <div className={styles.service_box_icon}>
                        {/* <HorizontalEllipsisIcon height='100%' /> */}
                      </div>
                      {/* {badClients?.find(a=> a.utente === selectedTask.utente) && <div style={{ width: '18px', height: '18px', borderRadius: '100px', display:'flex', alignItems: 'center', justifyContent: 'center'}}>
                           <BadIcon style={{top: '0px', width: '15px', height: '15px'}}/>
                        </div>} */}
                    </div>
                    <div className={styles.footer}>
                      <div className={styles.footer_client}>
                        {selectedTask.nome
                          ? `${selectedTask.nome} ${selectedTask.cognome}`
                          : selectedTask.nota
                            ? selectedTask.nota
                            : selectedTask?.self_prenotatione 
                              ? 'Da salone' 
                              : 'Utente ' + selectedTask.idutente}
                        {badClients?.find(a=> a?.utente === selectedTask.utente) && 
                          <BadIcon style={{marginRight: 'auto', maxWidth: '14px', height: '14px', marginBottom: '5px'}}/>
                        }
                      </div>                 
                      <div className={styles.footer_box}>
                        {!(isMobile && sizeValue === 1) && (
                          <div className={styles.footer_box_hour}>
                            {dayjs(selectedTask.orario_start, 'HH:mm:ss').format('HH:mm')} -{' '}
                            {dayjs(selectedTask.orario_end, 'HH:mm:ss').format('HH:mm')}
                          </div>
                        )}
                        <div className={styles.footer_box_icons}>
                          {Object.entries(selectBadges(selectedTask))
                            .filter(([_, value]) => value)
                            .map(([key, value]) => (
                              <>
                                <div style={{ width: `${Math.min(stepSize, 20) + 12}px` }} />
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={styles.create_icons_container}
                style={{
                  top: `${selectedTask.top + (selectedTask.size - iconSize - 10)}px`,
                  padding: '0px 10px',
                }}
              >
                {Object.entries(selectBadges(selectedTask))
                  .filter(([_, value]) => value)
                  .slice(0, 3)
                  .map(([key, value]) => (
                    <Badgets
                      //position= {`calc(100% - ${selectedTask.left * 10 + 10}px`}
                      iconSizeSmall={`${iconSize}px`}
                      key={key}
                      icon={badgetList[key]?.icon}
                      text={
                        key == 3
                          ? `${badgetList[key]?.text} ${dayjs(selectedTask.birthday).format(
                              'DD MMM',
                            )}`
                          : badgetList[key]?.text
                      }
                      hover={true}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SchedulerTask;
