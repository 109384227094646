import React, { useState } from 'react';
import styles from './CalendarHeaderModal.module.scss';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import { ReactComponent as ArrowRIcon } from '../../../../../../assets/svg/icons/arrow_r.svg';
import { ReactComponent as ArrowLIcon } from '../../../../../../assets/svg/icons/arrow_l.svg';
import ButtonSelector from '../../../../../Common/CustomSelector/ButtonSelector';
import ExtraTimeSelector from '../ExtraTimeSelector';
import CustomTimePicker from 'Containers/Common/CustomTimePicker';

const CalendarHeaderModal = ({
  calendarProps: {
    numberOfActiveDays,
    daySelectedValues,
    calendarWorkers,
    searchParams,
    setSearchParams,
    workerId,
    stepSize,
    setStepSize,
    workTime: {
      startWorkTime,
      endWorkTime,
      setStartWorkTime,
      setEndWorkTime,
    }
  },
  closeModal,
}) => {
  const [tempNumberOfActiveDays, setTempNumberOfActiveDays] = useState(numberOfActiveDays);
  const [tempActiveWorkerId, setTempActiveWorkerId] = useState(workerId);
  const [tempStepSize, setTempStepSize] = useState(stepSize);
  const [tempStartWorkTime, setTempStartWorkTime] = useState(startWorkTime);
  const [tempEndWorkTime, setTempEndWorkTime] = useState(endWorkTime);
  return (
    <div className={styles.header_modal_container}>

      <div className={styles.selector_container}>
        <ButtonSelector
          options={calendarWorkers}
          value={tempActiveWorkerId}
          setValue={(activeWorkerId) => {
            setTempActiveWorkerId(activeWorkerId);
          }}
          width={'100%'}
          marginLeft={0}
        />
      </div>
      <div className={styles.selector_container}>
        <ButtonSelector
          options={daySelectedValues}
          value={tempNumberOfActiveDays}
          setValue={(numberOfActiveDays) => {
            setTempNumberOfActiveDays(numberOfActiveDays);
          }}
          width={'100%'}
          marginLeft={0}
        />
      </div>
      <h4 className={styles.text}>Zoom del calendario </h4>
      <input
        type='range'
        min='9'
        max='24'
        value={tempStepSize}
        onChange={(e) => {
          setTempStepSize(+e.target.value);
        }}
      />
      <h3 className={styles.text}>
        Orari visibili su calendario
      </h3>
      <div className={styles.time}>
        <CustomTimePicker
          timeStep={30}
          value={
            dayjs(`${tempStartWorkTime?.hours}:${tempStartWorkTime?.minutes}`, 'HH:mm')
          }
          setValue={(value) => {
            setTempStartWorkTime({
              hours: value.$H,
              minutes: value.$m,
            })
          }}
          title={'dal'}
          maxTime={
            dayjs(tempEndWorkTime?.hours ? `${tempEndWorkTime?.hours - 5}:${tempEndWorkTime?.minutes}` : '22:00', 'HH:mm')
          }
        />
        <CustomTimePicker
          timeStep={30}
          value={
            dayjs(`${tempEndWorkTime?.hours}:${tempEndWorkTime?.minutes}`, 'HH:mm').subtract(30, 'minute')
          }
          setValue={(value) => {
            value = value.add(30, 'minute')
            setTempEndWorkTime({
              hours: value.$H ? value.$H : 24,
              minutes: value.$m,
            })
          }}
          minTime={
            dayjs(`${tempStartWorkTime?.hours}:${tempStartWorkTime?.minutes}`, 'HH:mm')
          }
          title={'al'}
        />
      </div>
      <Button
        className={styles.confirm_button}
        onClick={() => {
          searchParams.set('activeWorkerId', tempActiveWorkerId);
          searchParams.set('numberOfActiveDays', tempNumberOfActiveDays);
          setSearchParams(searchParams);
          setStepSize(tempStepSize);
          setStartWorkTime(tempStartWorkTime);
          setEndWorkTime(tempEndWorkTime);
          closeModal();
        }}
        variant='contained'
        width={'100%'}
      >
        Salva
      </Button>

    </div>
  );
};

export default CalendarHeaderModal;
