import React from 'react';
import styles from './CalendarHeader.module.scss';
import ButtonSelector from '../../../Common/CustomSelector/ButtonSelector';
import useWindowSize from '../../../../utils/useWindowSize';
import { ReactComponent as Filter } from '../../../../assets/svg/icons/filtri.svg';
import HeaderInject from '../../../Common/HeaderInject';
import SearchInput from '../../../Common/SearchInput';
import { useDispatch, useSelector } from 'react-redux';
import { getCalendarWorkers } from '../../store/selectors';
import DaySelector from './components/DaySelector';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import ExtraTimeSelector from './components/ExtraTimeSelector';
import { modalActions } from '../../../Modals/store';
import CustomDatePicker from '../../../Common/CustomDatePicker';

const daySelectedValues = [
  {
    name: 'Giorno',
    value: 1,
  },
  {
    name: '3 Giorni',
    value: 3,
  },
  {
    name: '7 Giorni',
    value: 7,
  },
  {
    name: '15 Giorni',
    value: 15,
  },
  {
    name: '30 Giorni',
    value: 30,
  },
];

const CalendarHeader = ({
  searchParams,
  setSearchParams: setSearchParamsValues,
  startDay,
  numberOfActiveDays,
  activeDays,
  searchString,
  setSearchString,
  activeWorker,
  setStepSize,
  stepSize,
  workTime,
}) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const setSearchParams = (searchParams) => {
    const params = new URLSearchParams(searchParams);
    const paramsString = JSON.stringify(Object.fromEntries(params));
    localStorage.setItem('searchParams', paramsString);
    setSearchParamsValues(searchParams);
  };
  const calendarWorkers = useSelector(getCalendarWorkers());
  const selectorWorkers = [
    {
      value: 0,
      name: 'Tutte le poltrone',
    },
    ...calendarWorkers.map((worker) => {
      return { value: worker.value, name: worker.name };
    }),
  ];

  return (
    <HeaderInject className={isMobile && 'calendar'}>
      {isMobile ? (
        <div className={styles.header_container_mobile}>
          <div className={styles.filter_and_search_container}>
            <div className={styles.header_container__search}>
              <SearchInput
                placeholder={'Cerca'}
                value={searchString}
                setValue={setSearchString}
                backgroundColor={'white'}
              />
            </div>
            <div className={styles.filter_icons_container}>
              <Filter
                onClick={() =>
                  dispatch(
                    modalActions.OPEN_MODAL.SUCCESS({
                      type: 'calendarHeaderModal',
                      props: {
                        numberOfActiveDays,
                        daySelectedValues,
                        calendarWorkers: selectorWorkers,
                        searchParams,
                        setSearchParams,
                        setStepSize,
                        workTime,
                        stepSize,
                        workerId: activeWorker?.idpoltrona || 0,
                      },
                    }),
                  )
                }
              />
              {numberOfActiveDays === 1 && dayjs().isSameOrBefore(startDay, 'day') ? (
                <ExtraTimeSelector title={''} startDay={startDay} />
              ) : null}
            </div>
          </div>
          <div className={styles.date_container}>
            <Button
              disableElevation
              variant='contained'
              className={styles.calendar_today_button}
              onClick={() => {
                searchParams.set('startDay', dayjs().format('YYYY-MM-DD'));
                setSearchParams(searchParams);
              }}
            >
              Oggi
            </Button>
            <DaySelector
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              startDay={startDay}
              numberOfActiveDays={numberOfActiveDays}
              activeDays={activeDays}
            />
          </div>
        </div>
      ) : (
        <div className={styles.header_container}>
          <div className={styles.header_container__item}>
            <Button
              disableElevation
              variant='contained'
              className={styles.calendar_today_button}
              onClick={() => {
                if (searchParams.get('startDay') != dayjs().format('YYYY-MM-DD'))
                  searchParams.set('reset', 1);
                else searchParams.set('reset', 2);

                searchParams.set('startDay', dayjs().format('YYYY-MM-DD'));

                setSearchParams(searchParams);
              }}
            >
              Oggi
            </Button>
            <DaySelector
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              startDay={startDay}
              numberOfActiveDays={numberOfActiveDays}
              activeDays={activeDays}
            />
          </div>
          <div className={styles.header_container__search}>
            <SearchInput
              placeholder={'Cerca il nome del cliente o del servizio'}
              value={searchString}
              setValue={setSearchString}
            />
          </div>
          <div className={styles.header_container__item}>
            <ButtonSelector
              options={selectorWorkers}
              value={activeWorker?.idpoltrona || 0}
              setValue={(activeWorkerId) => {
                searchParams.set('activeWorkerId', activeWorkerId);
                setSearchParams(searchParams);
              }}
            />
            <ButtonSelector
              options={daySelectedValues}
              value={numberOfActiveDays}
              setValue={(numberOfActiveDays) => {
                searchParams.set('numberOfActiveDays', numberOfActiveDays);
                setSearchParams(searchParams);
              }}
            />
            <div className={styles.extra_time_selector_container}>
              <ExtraTimeSelector
                daySettings={numberOfActiveDays === 1 && dayjs().isSameOrBefore(startDay, 'day')}
                title={'Impostazioni'}
                startDay={startDay}
                stepSize={stepSize}
                setStepSize={setStepSize}
                workTime={workTime}
              />
            </div>
          </div>
        </div>
      )}
    </HeaderInject>
  );
};

export default CalendarHeader;
