import { Navigate, useLocation } from 'react-router-dom';

import { ROUTES } from '../constants';

export const PrivateRoute = ({ component: RouteComponent, isAuthenticated }) => {
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to={ROUTES.SIGN_IN} state={{ from: location }} />;
  }

  return <RouteComponent/>;
};
