import React, { useEffect, useState } from 'react';
import styles from './ClientModal.module.scss';
import AvatarIcon from '../../../Common/AvatarIcon';
import { ReactComponent as WhatsappIcon } from '../../../../assets/svg/icons/whatsapp.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/svg/icons/chiamate.svg';
import { ReactComponent as MailIcon } from '../../../../assets/svg/icons/mail.svg';
import { ReactComponent as Bin } from '../../../../assets/svg/icons/trash_red.svg';
import { ReactComponent as Edit } from '../../../../assets/svg/edit.svg';
import { ReactComponent as EditBlack } from '../../../../assets/svg/icons/modifica.svg';
import { ReactComponent as Verified } from '../../../../assets/svg/verified.svg';
import { ReactComponent as Plus } from '../../../../assets/svg/plus_circle_blue.svg';
import { ReactComponent as NewIcon } from 'assets/svg/badgets/new_client.svg';
import { ReactComponent as BadIcon } from 'assets/svg/icons/warning_user.svg';

import { useDispatch, useSelector } from 'react-redux';
import { getClient } from '../../store/selectors';
import { clientActions } from '../../store';
import dayjs from 'dayjs';
import HoveredInfoIcon from '../../../Common/HoveredInfoIcon';
import { Button } from '@mui/material';
import ConfirmDeleteModal from '../../../Common/ConfirmDeleteModal';
import IconButton from '../../../Common/Buttons/IconButton';
import useWindowSize from '../../../../utils/useWindowSize';
import clx from 'classnames';
import ButtonSelector from '../../../Common/CustomSelector/ButtonSelector';
import { getCalendarWorkers } from '../../../Scheduler/store/selectors';
import { salonManagementActions } from '../../../SalonManagement/store';
import { getWorker, getWorkers } from '../../../SalonManagement/store/selectors';
import { getActiveSalonId } from '../../../Auth/store/selectors';
import ConfirmSecondaryModal from '../../../Common/ConfirmSecondaryModal';
import EditPhone from './EditPhone';
import AddNote from './AddNote';
import EditNote from './EditNote';
import DeleteNote from './DeleteNote';
import ConfirmModal from 'Containers/Common/ConfirmModal';
import { modalActions } from '../../../Modals/store';
import { clientStatuses } from '../constants';
import Switcher from 'Containers/Common/Switcher';
import { getActiveSalonePaymentInfo } from 'utils/salonToken';
import IOSSwitch from 'Containers/Common/CustomSwitch';
import { allBadClients } from 'Containers/Clients/store/selectors';

export default ({ client_id, totPren, closeModal }) => {
  const badClients = useSelector(allBadClients());
  const dispatch = useDispatch();
  const client = useSelector(getClient());
  const workers = useSelector(getWorkers(0, false));
  const { isMobile } = useWindowSize();
  const [deleteClientModalIsOpen, setDeleteClientModalIsOpen] = useState(false);
  const [enableClientModalIsOpen, setEnableClientModalIsOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);
  const [isEditNoteModalOpen, setIsEditNoteModalOpen] = useState(false);
  const [isDeleteNoteModalOpen, setIsDeleteNoteModalOpen] = useState(false);
  const [isModalNoteData, setIsModalNoteData] = useState({});
  const isBadClient = badClients?.find(a=> a?.utente === client_id);

  const [deleteClientAppointment, setDeleteClientAppointment] = useState(false);
  const activeSalonId = useSelector(getActiveSalonId());
  const workersById = (workers || []).reduce((acc, worker) => {
    acc[worker.idpoltrona] = worker.nome_referente;
    return acc;
  }, {});
  useEffect(() => {
    dispatch(clientActions.GET_CLIENT.REQUEST({ idcliente: client_id }));
  }, [dispatch, client_id]);
  useEffect(() => {
    activeSalonId && dispatch(salonManagementActions.GET_WORKERS.REQUEST());
    return () => dispatch(salonManagementActions.GET_WORKERS.CLEAR());
  }, [activeSalonId]);
  const activeAppointments = client.appointments?.filter(
    (appointment) => !dayjs().isAfter(appointment.startDate, 'day'),
  );
  const oldAppointments = client.appointments?.filter((appointment) =>
    dayjs().isAfter(appointment.startDate, 'day'),
  );
  const clientNotes = client.notes;
  const paymentDisabled = getActiveSalonePaymentInfo();

  const handleCheckChange = (event) => {
    event.preventDefault();
    dispatch(
      clientActions.EDIT_CLIENT.REQUEST({
        idutente: client_id,
        tipo: event.target.checked ? 6 : 0,
      }),
    );
  };


  return (
    <>
      <ConfirmSecondaryModal
        isModalOpen={deleteClientModalIsOpen}
        setIsModalOpen={setDeleteClientModalIsOpen}
        title={'Disabilita cliente'}
        subtitle={'Sei sicuro di voler disabilitare il cliente?'}
        buttons={['Disabilita']}
        buttonsActions={[
          () =>
            dispatch(
              clientActions.DELETE_CLIENT.REQUEST(
                {
                  idUtente: client_id,
                },
                closeModal,
              ),
            ),
        ]}
      />

      <EditPhone
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
        phone={client?.cellulare}
        verified={client?.cellulare_verificato}
        client_id={client_id}
      />

      <AddNote
        isModalOpen={isAddNoteModalOpen}
        setIsModalOpen={setIsAddNoteModalOpen}
        client_id={client_id}
      />

      <EditNote
        isModalOpen={isEditNoteModalOpen}
        setIsModalOpen={setIsEditNoteModalOpen}
        client_id={client_id}
        nota={isModalNoteData}
      />

      <DeleteNote
        isModalOpen={isDeleteNoteModalOpen}
        setIsModalOpen={setIsDeleteNoteModalOpen}
        client_id={client_id}
        nota={isModalNoteData}
      />

      <ConfirmSecondaryModal
        isModalOpen={deleteClientModalIsOpen}
        setIsModalOpen={setDeleteClientModalIsOpen}
        title={'Disabilita cliente'}
        subtitle={'Sei sicuro di voler disabilitare il cliente?'}
        buttons={['Disabilita']}
        buttonsActions={[
          () =>
            dispatch(
              clientActions.DELETE_CLIENT.REQUEST(
                {
                  idUtente: client_id,
                },
                closeModal,
              ),
            ),
        ]}
      />

      <ConfirmModal
        isModalOpen={enableClientModalIsOpen}
        setIsModalOpen={setEnableClientModalIsOpen}
        title={'Sei sicuro di voler abilitare il cliente? '}
        action={() =>
          dispatch(
            clientActions.ENABLE_CLIENT.REQUEST(
              {
                idUtente: client_id,
              },
              closeModal,
            ),
          )
        }
      />
      <ConfirmDeleteModal
        isModalOpen={deleteClientAppointment}
        setIsModalOpen={setDeleteClientAppointment}
        title={'Sei sicuro di voler disabilitare la prenotazione del cliente ?'}
        buttons={['Annulla', 'Disabilita']}
        action={() =>
          dispatch(
            clientActions.DELETE_CLIENT_APPOINTMENT.REQUEST(deleteClientAppointment, () =>
              setDeleteClientAppointment(false),
            ),
          )
        }
      />
      {
        // !!client.value &&
        <div
          className={clx(styles.client_modal_container, {
            [styles.client_modal_container_mobile]: isMobile,
          })}
        >
          <div className={styles.client_modal_body}>
            <div className={styles.avatar_and_status_block}>
              <div className={styles.avatar_block}>
                <div className={styles.avatar_container}>
                  <AvatarIcon name={client.name} icon={client.icon} size={'78px'} />
                  {totPren < 4 && <NewIcon style={{bottom: '0px'}}/>}
                </div>
                <div className={styles.client_name}>{client.name}</div>
              </div>
              <div className={styles.status_selector_container}>
                <ButtonSelector
                  value={client.tipo}
                  setValue={(value) => {
                    dispatch(
                      clientActions.EDIT_CLIENT.REQUEST({
                        idutente: client_id,
                        tipo: value,
                      }),
                    );
                  }}
                  options={clientStatuses}
                  title={'Categoria Cliente'}
                  marginLeft={0}
                  width={'100%'}
                  height={'51px'}
                />
              </div>
            </div>
            {isBadClient && (<div className={styles.bad_section}>
              <BadIcon style={{minWidth: '24px', width:'24px', height: '24px'}}/>
              <div className={styles.info_cell}>
                <div className={styles.info_title}>Attenzione: disdette ricorrenti</div>
                <div className={styles.info_value} style={{color: '#FF6A88'}}>{"Questo utente ha disdetto troppo tardi il 20% delle prenotazioni negli ultimi 6 mesi. Imposta la categoria 'Paga online' per proteggerti da future disdette dell’ultimo minuto."}</div>
              </div>
            </div>)}
            {paymentDisabled !== 1 && (
              <div className={styles.info_cell}>
                <div className={styles.info_title}>Paga solo in app</div>
                <div className={styles.info_value}>
                  <IOSSwitch 
                  checked={client.tipo === 6}
                  onChange={handleCheckChange}
                  />
                </div>
              </div>
            )}
            <div className={styles.info_cell}>
              <div className={styles.info_title}>Data iscrizione</div>
              <div className={styles.info_value}>
                {dayjs(client.registrationDate).format('DD MMMM YYYY')}
              </div>
            </div>
            <div className={styles.info_cell}>
              <div className={styles.info_title}>Ultimo accesso</div>
              <div className={styles.info_value}>
                {dayjs(client.lastAccess).format('DD MMMM YYYY')}
              </div>
            </div>

            <div className={styles.info_cell}>
              <div className={styles.info_title}>Telefono</div>
              <div className={styles.info_value}>
                <Edit
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setIsEditModalOpen(true);
                  }}
                />{' '}
                <span className={styles.phone}>{client.phone}</span>{' '}
                {client.cellulare_verificato ? <Verified /> : null}
              </div>
            </div>
            <div className={styles.info_cell}>
              <div className={styles.info_title}>E-mail</div>
              <div className={styles.info_value}>{client.email}</div>
            </div>
            <div className={styles.info_cell}>
              <div className={styles.info_title}>Contatta il cliente</div>
            </div>
            <div className={styles.info_icons_container}>
              <IconButton
                svg={<WhatsappIcon />}
                type={'whatsapp'}
                size={'49px'}
                isActive={!!client.phone}
                value={client.phone}
                margin={'0 10px 0 0'}
              />
              <IconButton
                svg={<PhoneIcon />}
                type={'phone'}
                size={'49px'}
                isActive={!!client.phone}
                value={client.phone}
                margin={'0 10px 0 0'}
              />
              <IconButton
                svg={<MailIcon />}
                type={'email'}
                size={'49px'}
                isActive={!!client.email}
                value={client.email}
                margin={'0 10px 0 0'}
              />
            </div>
            <div className={styles.info_cell}>
              <div className={styles.info_title}>Storico note</div>
              <div className={styles.aggiungi_nota} onClick={() => setIsAddNoteModalOpen(true)}>
                Aggiungi nota <Plus style={{ marginLeft: '5px' }} />
              </div>
            </div>
            <div className={styles.appointments_history_block}>
              {clientNotes?.length ? (
                clientNotes?.map((note) => (
                  <div key={note.idutentenota} className={styles.appointments_history_row}>
                    <div className={styles.appointments_history_info_cell}>{note?.nota}</div>
                    <div className={styles.appointments_history_date_cell}>
                      {dayjs(note.created_at).format('DD/MM/YY HH:mm')}
                    </div>
                    <div className={`${styles.appointments_history_info_cell} ${styles.note_cell}`}>
                      <div
                        className={styles.edit_note}
                        onClick={() => {
                          setIsModalNoteData(note);
                          setIsEditNoteModalOpen(true);
                        }}
                      >
                        <EditBlack />
                      </div>{' '}
                      <div
                        className={styles.delete_note}
                        onClick={() => {
                          setIsModalNoteData(note);
                          setIsDeleteNoteModalOpen(true);
                        }}
                      >
                        <Bin />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>Non ci sono note</div>
              )}
            </div>
            <div className={styles.appointment_title}>Prenotazioni</div>
            <div className={styles.active_appointments_block}>
              {activeAppointments?.length ? (
                activeAppointments?.map(
                  (appointment) =>
                    workersById[appointment.worker] && (
                      <div key={appointment.id} className={styles.appointment_container}>
                        <div className={styles.appointment_info}>
                          <div className={styles.appointment_info_value}>
                            {appointment?.services?.length
                              ? appointment.services?.join(' + ')
                              : 'Senza servizio'}
                          </div>
                          <div className={styles.appointment_info_value}>
                            {dayjs(appointment.startDate).format('dddd DD MMMM')} alle{' '}
                            {dayjs(appointment.startTime, 'HH:mm:ss').format('HH:mm')}
                          </div>
                          <div className={styles.appointment_info_value}>
                            con {workersById[appointment.worker]}
                          </div>
                        </div>
                        <div
                          onClick={() =>
                            setDeleteClientAppointment({
                              idPrenotazione: appointment.id,
                              idPoltrona: appointment.worker_id,
                              deleteAllRicorrenti: false,
                            })
                          }
                          className={styles.appointment_action}
                        >
                          Disdici prenotazione &gt;
                        </div>
                      </div>
                    ),
                )
              ) : (
                <div>Non ci sono prenotazioni in corso</div>
              )}
            </div>
            <div className={styles.appointment_title}>Statistiche</div>
            <div className={styles.statistica_block}>
              <div className={styles.statistica_info}>
                <div className={styles.statistica_title}>Giorno Preferito</div>
                <div className={styles.statistica_value}>{client.favoriteDay}</div>
              </div>

              <div className={styles.statistica_info}>
                <div className={styles.statistica_title}>Media prenotazioni</div>
                <div className={styles.statistica_value}>{client.appointmentPerMonth}</div>
              </div>

              <div className={styles.statistica_info}>
                <div className={styles.statistica_title}>Servizio Preferito</div>
                <div className={styles.statistica_value}>{client.favoriteService}</div>
              </div>

              <div className={styles.statistica_info}>
                <div className={styles.statistica_title}>Orario preferito</div>
                <div className={styles.statistica_value}>
                  {client.favoriteTimes?.map((time) => time.nomeFascia).join(' | ')}
                </div>
              </div>

              <div className={styles.statistica_info}>
                <div className={styles.statistica_title}>Prenotazioni totali</div>
                <div className={styles.statistica_value}>{client.appointments?.length}</div>
              </div>
            </div>
            <div className={styles.appointment_title}>Storico prenotazioni</div>
            <div className={styles.appointments_history_block}>
              {oldAppointments?.length ? (
                oldAppointments?.map(
                  (appointment) =>
                    workersById[appointment.worker] && (
                      <div key={appointment.id} className={styles.appointments_history_row}>
                        <div className={styles.appointments_history_info_cell}>
                          {appointment?.services?.length
                            ? appointment.services?.join(' + ')
                            : 'Senza servizio'}
                        </div>
                        <div className={styles.appointments_history_info_cell}>
                          con {workersById[appointment.worker]}
                        </div>
                        <div className={styles.appointments_history_date_cell}>
                          {dayjs(appointment.startDate).format('DD/MM/YY')}
                        </div>
                      </div>
                    ),
                )
              ) : (
                <div>Non ci sono prenotazioni in corso</div>
              )}
            </div>
          </div>

          <div style={{ overflowX: 'hidden' }}>
            <hr
              style={{
                width: 'calc(100% + 50px)',
                transform: 'translate(-50px, 0px)',
              }}
            />
          </div>
          <div className={styles.footer}>
            <div className={styles.title_wrapper}>
              <div className={styles.title}>Disabilita cliente</div>
              <HoveredInfoIcon
                description={`Disabilitando il cliente non sarà più possibile prenotare appuntamenti per lui e
                 anche lui non potrà più prenotare appuntamenti nel tuo salone
                .`}
                sidePosition={'up'}
              />
            </div>

            {!client.disabilitato && (
              <Button
                onClick={() => setDeleteClientModalIsOpen(true)}
                className={styles.delete_button}
              >
                <Bin />
                Disabilita
              </Button>
            )}
            {!!client.disabilitato && (
              <Button
                onClick={() => setEnableClientModalIsOpen(true)}
                variant='contained'
                className={styles.enable_button}
              >
                Abilita
              </Button>
            )}
          </div>
        </div>
      }
    </>
  );
};
