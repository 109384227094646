import styles from './SalonInfo.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveSalon } from '../../store/selectors';
import React from 'react';
import TitleSectionBack from '../../../Common/TitleSectionBack';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { modalActions } from '../../../Modals/store';
import AvatarIcon from '../../../Common/AvatarIcon';
import TextField from '../../../Common/TextField';
import HoveredInfoIcon from '../../../Common/HoveredInfoIcon';
import Switcher from '../../../Common/Switcher';
import { Button } from '@mui/material';
import { ReactComponent as EditIconInCircle } from '../../../../assets/svg/icons/editBlueCircle.svg';
import { authActions } from '../../store';
import useWindowSize from '../../../../utils/useWindowSize';
import clx from 'classnames';
import ButtonSelector from 'Containers/Common/CustomSelector/ButtonSelector';
import { getActiveSalonePaymentInfo } from 'utils/salonToken';

const bookableDayOptions = [
  {
    name: '7',
    value: 7,
  },
  {
    name: '15',
    value: 15,
  },
  {
    name: '21',
    value: 21,
  },
  {
    name: '30',
    value: 30,
  },
  {
    name: '60',
    value: 60,
  },
  {
    name: 'Blocca',
    value: 0,
  },
];

const visibleInPastOptions = [
  {
    name: '1',
    value: 1,
  },
  {
    name: '7',
    value: 7,
  },
  {
    name: '15',
    value: 15,
  },
  {
    name: '30',
    value: 30,
  },
];

const nMaxPrenotazioniOptions = [
  {
    name: '1',
    value: 1,
  },
  {
    name: '3',
    value: 3,
  },
  {
    name: '5',
    value: 5,
  },
  {
    name: '10',
    value: 10,
  },
];

const SalonInfo = () => {
  const dispatch = useDispatch();
  const salon = useSelector(getActiveSalon());
  const { isMobile } = useWindowSize();
  const paymentDisabled = getActiveSalonePaymentInfo();
  const initialValues = {
    nomeSalone: salon.name || '',
    tel: salon.phoneNumber || '',
    nMaxPrenotazioni: +salon.numberOfAppointments || 0,
    n_giorni_prenotabili: +salon.numberOfBookableDays || 0,
    n_giorni_passati: +salon.giorniVisibiliSalonePassato || 0,
    showPrice: +salon.showPrice,
    isOldStyle: +salon.isOldStyle,
    serviceCombos: 0,
    email: salon.email || '',
    coverImg: '',
    avatarImg: '',
    new_user_must_pay: salon.new_user_must_pay
  };


  const validationSchema = {
    nomeSalone: yup.string('Inserisci la tua nome').required('Nome è richiesta'),
    nMaxPrenotazioni: yup
      .number()
      // .required('nMaxPrenotazioni è richiesta')
      .typeError('Inserisci la tua nMaxPrenotazioni'),
    n_giorni_prenotabili: yup
      .number()
      // .required('n_giorni_prenotabili è richiesta')
      .typeError('Inserisci la tua n_giorni_prenotabili'),
    n_giorni_passati: yup
      .number()
      // .required('n_giorni_passati è richiesta')
      .typeError('Inserisci la tua n_giorni_passati'),
  };

  return (
    <div className={clx(styles.salon_container, { [styles.salon_container_mobile]: isMobile })}>
      <TitleSectionBack
        title={'Modifica informazioni'}
        description={
          'Modifica le informazioni del tuo salone, imposta un limite di prenotazioni massime effettuabili dall utente e il numero di giorni massimi prenotabili'
        }
      />
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        enableReinitialize={true}
        onSubmit={(values) => {
          const { coverImg, avatarImg, ...req } = values;
          dispatch(authActions.EDIT_SALON_INFO.REQUEST(req));
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className={styles.salon_card}>
              <div className={styles.form_header}>Impostazioni salone</div>
              <div className={styles.form_container}>
                <div className={styles.image_block}>
                  <div className={styles.form_title_wrapper}>
                    <div className={styles.form_title}>Immagine di Copertina e di profilo</div>
                    <HoveredInfoIcon
                      description={
                        'Immagine di copertina e di profilo del tuo salone che i tuoi clienti visualizzano per riconoscerti'
                      }
                    />
                  </div>
                  <div
                    className={styles.cover_image_wrapper}
                    style={{ backgroundImage: `url(${values.coverImg || salon.coverImg})` }}
                  >
                    <EditIconInCircle
                      className={styles.cover_image_action}
                      onClick={() =>
                        dispatch(
                          modalActions.OPEN_MODAL.SUCCESS({
                            type: 'createSalonCoverModal',
                            callback: (value) => setFieldValue('coverImg', value),
                          }),
                        )
                      }
                    />
                    <div className={styles.avatar_container}>
                      <EditIconInCircle
                        onClick={() =>
                          dispatch(
                            modalActions.OPEN_MODAL.SUCCESS({
                              type: 'createSalonAvatarModal',
                              callback: (value) => setFieldValue('avatarImg', value),
                            }),
                          )
                        }
                      />
                      <AvatarIcon
                        name={salon.name}
                        icon={values.avatarImg || salon.icon}
                        size={'100px'}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.main_block_wrapper}>
                  <Field
                    name={'nomeSalone'}
                    id={'nomeSalone'}
                    label='Nome del salone'
                    component={TextField}
                    height={'50px'}
                    margin={'0px 0px 20px 0px'}
                    placeholder={'Campo di testo da compilare'}
                  />
                  <Field
                    name={'email'}
                    id={'email'}
                    label='E-mail'
                    component={TextField}
                    height={'50px'}
                    placeholder={'Demo@demo.it'}
                    margin={'0px 0px 20px 0px'}
                    disabled={true}
                  />
                  <div className={clx(styles.main_block_row)}>
                    <div className={styles.block_row_title}>
                      Numero massimo di prenotazioni
                      <HoveredInfoIcon
                        description={
                          'Imposta un numero massimo di prenotazioni che un cliente può avere attive contemporaneamente'
                        }
                      />
                    </div>{' '}
                    <div className={styles.appointments_count_wrapper}>
                      {/* <Field
                        name={'nMaxPrenotazioni'}
                        id={'nMaxPrenotazioni'}
                        label=''
                        component={TextField}
                        margin={'0'}
                      /> */}
                      <ButtonSelector
                        name={'nMaxPrenotazioni'}
                        id={'nMaxPrenotazioni'}
                        options={
                          nMaxPrenotazioniOptions.find((p) => p.value === values.nMaxPrenotazioni)
                            ? nMaxPrenotazioniOptions
                            : [
                                ...nMaxPrenotazioniOptions,
                                {
                                  name: `${values.nMaxPrenotazioni}`,
                                  value: values.nMaxPrenotazioni,
                                },
                              ]
                        }
                        value={values.nMaxPrenotazioni}
                        setValue={(value) => {
                          setFieldValue('nMaxPrenotazioni', value);
                        }}
                        marginLeft='0px'
                        width='100%'
                      />
                    </div>
                  </div>
                  <div className={clx(styles.main_block_row)}>
                    <div className={styles.block_row_title}>
                      Numero massimo di giorni prenotabili
                      <HoveredInfoIcon
                        description={
                          'Imposta un numero massimo di giorni che un cliente può visualizzare in app per effettuare una prenotazione'
                        }
                      />
                    </div>
                    <div className={styles.appointments_count_wrapper}>
                      {/* <Field
                        name={'n_giorni_prenotabili'}
                        id={'n_giorni_prenotabili'}
                        label=''
                        component={TextField}
                        height={'50px'}
                        margin={'0'}
                      /> */}
                      <ButtonSelector
                        name={'n_giorni_prenotabili'}
                        id={'n_giorni_prenotabili'}
                        options={
                          bookableDayOptions.find((p) => p.value === values.n_giorni_prenotabili)
                            ? bookableDayOptions
                            : [
                                ...bookableDayOptions,
                                {
                                  name: `${values.n_giorni_prenotabili}`,
                                  value: values.n_giorni_prenotabili,
                                },
                              ]
                        }
                        value={values.n_giorni_prenotabili}
                        setValue={(value) => {
                          setFieldValue('n_giorni_prenotabili', value);
                        }}
                        marginLeft='0px'
                        width='100%'
                      />
                    </div>
                  </div>
                  <div className={clx(styles.main_block_row)}>
                    <div className={styles.block_row_title}>
                      Numero massimo di giorni passati
                      <HoveredInfoIcon
                        description={
                          'Imposta il numero di giorni nel passato che vuoi visualizzare sul calendario'
                        }
                      />
                    </div>

                    <div className={styles.appointments_count_wrapper}>
                      <ButtonSelector
                        name={'n_giorni_passati'}
                        id={'n_giorni_passati'}
                        options={
                          visibleInPastOptions.find((p) => p.value === values.n_giorni_passati)
                            ? visibleInPastOptions
                            : [
                                ...visibleInPastOptions,
                                {
                                  name: `${values.n_giorni_passati}`,
                                  value: values.n_giorni_passati,
                                },
                              ]
                        }
                        value={values.n_giorni_passati}
                        setValue={(value) => {
                          setFieldValue('n_giorni_passati', value);
                        }}
                        marginLeft='0px'
                        width='100%'
                      />
                    </div>
                  </div>
                  
                  <Field
                    name={'tel'}
                    id={'tel'}
                    label='Numero di telefono'
                    component={TextField}
                    height={'50px'}
                    placeholder={'333 33 33 333'}
                  />

                  {!(
                      <div className={styles.main_block_row}>
                        <div className={styles.selector_title_wrapper}>
                          <div className={styles.selector_title}>Mostra prezzi in App</div>
                          <HoveredInfoIcon
                            description={
                              "Il prezzo dei servizi sarà visibile ai clienti che prenotano tramite l'app"
                            }
                          />
                        </div>
                        <div className={styles.switcher_container}>
                          <Switcher
                            value={!values.showPrice}
                            setValue={(value) => setFieldValue('showPrice', +value)}
                            isFullWidth={isMobile}
                          />
                        </div>
                      </div>
                  )}

                  <div className={styles.main_block_row}>
                    <div className={styles.selector_title_wrapper}>
                      <div className={styles.selector_title}>Attiva combo servizi</div>
                      <HoveredInfoIcon
                        description={
                          'Dai la possibilità ai clienti di selezionare più di 1 servizio quando prenotano'
                        }
                      />
                    </div>
                    <div className={styles.switcher_container}>
                      <Switcher
                        value={values.isOldStyle}
                        setValue={(value) => setFieldValue('isOldStyle', value ? 0 : 1)}
                        isFullWidth={isMobile}
                      />
                    </div>
                  </div>

                  {!paymentDisabled && <div className={styles.main_block_row}>
                    <div className={styles.selector_title_wrapper}>
                      <div className={styles.selector_title}>Pagamento online per i nuovi clienti</div>
                      <HoveredInfoIcon
                        description={
                          'Abilita i pagamenti obbligatori anticipati per i nuovi clienti che non hanno ancora effettuato una prenotazione nel tuo salone.'
                        }
                      />
                    </div>
                    <div className={styles.switcher_container}>
                      <Switcher
                        value={!values.new_user_must_pay}
                        setValue={(value) => setFieldValue('new_user_must_pay', +value)}
                        isFullWidth={isMobile}
                      />
                    </div>
                  </div>}


                  <div className={styles.salon_actions}>
                    <Button className={styles.confirm_button} variant='contained' type={'submit'}>
                      Salva
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SalonInfo;
