import styles from './ExtraTimeSelector.module.scss';
import React, { useRef, useState } from 'react';
import { ReactComponent as Settings } from '../../../../../../assets/svg/icons/impostazioni.svg';
import useOutsideClick from '../../../../../../utils/useOutsideClick';
import clx from 'classnames';
import { modalActions } from '../../../../../Modals/store';
import { useDispatch } from 'react-redux';
import CustomTimePicker from 'Containers/Common/CustomTimePicker';
import dayjs from 'dayjs';

export default ({
  startDay,
  title = '',
  stepSize,
  setStepSize,
  daySettings = true,
  workTime: { setEndWorkTime, setStartWorkTime, startWorkTime, endWorkTime } = {
    setEndWorkTime: () => {},
    setStartWorkTime: () => {},
    startWorkTime: {},
    endWorkTime: {},
  },
}) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useOutsideClick(dropdownRef, () => {
    if (isDropdownOpen) setIsDropdownOpen(false);
  });

  return (
    <div
      ref={dropdownRef}
      className={clx(styles.extra_time_selector, {
        [styles['extra_time_selector_close']]: !isDropdownOpen,
      })}
    >
      <div className={styles.settings_title}>{title}</div>
      <Settings onClick={() => setIsDropdownOpen((i) => !i)} />
      <div className={styles.dropdown__menu}>
        <div className={styles.dropdown__menu_inner_list}>
          {!!daySettings && (
            <>
              <div
                onClick={() =>
                  dispatch(
                    modalActions.OPEN_MODAL.SUCCESS({
                      type: 'workerExtraWorkingTimeOneDayModal',
                      props: {
                        startDay,
                      },
                    }),
                  )
                }
                className={styles.dropdown__menu_item}
              >
                Apertura straordinaria
              </div>
              <div
                onClick={() =>
                  dispatch(
                    modalActions.OPEN_MODAL.SUCCESS({
                      type: 'workerClosuresOneDayModal',
                      props: {
                        startDay,
                      },
                    }),
                  )
                }
                className={styles.dropdown__menu_item}
              >
                Chiusura straordinaria
              </div>
            </>
          )}
          {!!stepSize && (
            <div className={styles.dropdown__item}>
              <h4 className={styles.text}>Zoom del calendario </h4>
              <input
                type='range'
                min='9'
                max='24'
                value={stepSize}
                onChange={(e) => {
                  setStepSize(+e.target.value);
                }}
              />
            </div>
          )}
          {!!stepSize && (
            <div className={styles.dropdown__item}>
              <h4 className={styles.text}>Orari visibili su calendario</h4>
              <div className={styles.dropdown__item_time}>
                {isDropdownOpen && (
                  <CustomTimePicker
                    timeStep={30}
                    value={dayjs(`${startWorkTime?.hours}:${startWorkTime?.minutes}`, 'HH:mm')}
                    setValue={(value) => {
                      setStartWorkTime({
                        hours: value.$H,
                        minutes: value.$m,
                      });
                    }}
                    title={'dal'}
                    maxTime={dayjs(
                      endWorkTime?.hours
                        ? `${endWorkTime?.hours - 5}:${endWorkTime?.minutes}`
                        : '22:00',
                      'HH:mm',
                    )}
                  />
                )}
                {isDropdownOpen && (
                  <CustomTimePicker
                    timeStep={30}
                    value={dayjs(`${endWorkTime?.hours}:${endWorkTime?.minutes}`, 'HH:mm').subtract(
                      30,
                      'minute',
                    )}
                    setValue={(value) => {
                      value = value.add(30, 'minute');
                      setEndWorkTime({
                        hours: value.$H ? value.$H : 24,
                        minutes: value.$m,
                      });
                    }}
                    minTime={dayjs(`${startWorkTime?.hours}:${startWorkTime?.minutes}`, 'HH:mm')}
                    title={'al'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
