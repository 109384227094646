import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NAV_BAR_ROUTES } from './constants';
import NavItem from './containers/NavItem';
import styles from './SideBar.module.scss';
import clx from 'classnames';
import useWindowSize from '../../utils/useWindowSize';
import { ReactComponent as ZbLogo } from '../../assets/svg/zb-logo.svg';
import { ReactComponent as ZLogoNoText } from '../../assets/svg/logo-noText-black.svg';
import { ufSaloniBannerPayments } from '../../utils/salonToken';
import { getActiveSalon } from 'Containers/Auth/store/selectors';
import { useSelector } from 'react-redux';
const SideBar = ({ sideBarIsOpen, setSideBarIsOpen }) => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState('');
  const { isMobile } = useWindowSize();
  const activeSalon = useSelector(getActiveSalon());

  useEffect(() => {
    if (location) {
      setActiveItem(location.pathname);
    }
  }, [location]);

  return (
    <aside
      className={clx(styles.sidebar__wrapper, {
        [styles.sidebar__wrapper__close]: !sideBarIsOpen && !isMobile,
        [styles.sidebar__wrapper__close_mobile]: !sideBarIsOpen && isMobile,
        [styles.sidebar__wrapper__mobile]: isMobile,
      })}
    >
      <div
        onClick={() => setSideBarIsOpen((i) => (isMobile ? !i : i))}
        className={styles.sidebar__inner}
      >
        <nav className={styles['nav-bar']}>
          <div className={styles.sidebar__logo}>
            {sideBarIsOpen ? <ZbLogo /> : <ZLogoNoText width={'30px'} height={'auto'} />}
          </div>
          <ul>
            {NAV_BAR_ROUTES.map((navItem, idx) => {
              if (navItem.label === 'Pagamenti') {
                /* if (
                  !ufSaloniBannerPayments.includes(activeSalon.idsalone) &&
                  activeSalon.disable_payment === 1
                )
                  return null;
                else */
                return (
                  <NavItem
                    key={idx}
                    navItem={navItem}
                    activeItem={activeItem}
                    isOpen={sideBarIsOpen}
                  />
                );
              } else {
                return (
                  <NavItem
                    key={idx}
                    navItem={navItem}
                    activeItem={activeItem}
                    isOpen={sideBarIsOpen}
                  />
                );
              }
            })}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SideBar;
