import './styles/index.scss';

import { ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SignIn } from './Containers/Auth/containers';
import { getActiveSalon, getAuth } from './Containers/Auth/store/selectors';
import PageWrapper from './Containers/Common/PageWrapper';
import Spinner from './Containers/Common/Spinner';
import { ReactComponent as CheckIcon } from 'assets/svg/settings.svg';
import { ROUTES } from './routes/constants';
import { PrivateRoute, PublicRoute } from './routes/containers';
import Error from './shared/Error/Error';
import Loader from './shared/Loader/Loader';
import muiTheme from './themes/muiTheme';
import useAuth from './utils/useAuth';
import { HomePage } from './Containers/Home/containers';
import SchedulerContainer from './Containers/Scheduler/containers/SchedulerContainer';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import GlobalModal from './Containers/Modals/containers/GlobalModal';
import GlobalToast from 'Containers/Toasts/containers/GlobalToast';
import { Shop } from './Containers/Shop';
import { Communications } from './Containers/Communications';
import {
  SalonManagementList,
  Services,
  Workers,
  WorkerWorkTime,
  Closures,
  ExtraWorkingTime,
  ServicesList,
} from './Containers/SalonManagement/containers';
import {
  ClientsList,
  SalonInfo,
  Security,
  SettingsList,
  SharingContainer,
  PaymentsContainer,
} from './Containers/Settings/containers';
import NotificationsList from './Containers/Notifications/containers/NotificationsList';
import WorkersList from './Containers/SalonManagement/containers/Services/containers/WorkersList';
import ServiceIconsList from './Containers/SalonManagement/containers/Services/containers/ServiceIconsList';
import { StatisticContainer } from './Containers/Statistic/containers';
import { RegistrationPageContainer } from './Containers/Auth/containers/RegistrationPage';
import { authActions } from './Containers/Auth/store';
import { settingsActions } from 'Containers/Settings/store';
import store from 'store/store';
import { getCustomerProducts } from 'Containers/Settings/store/selectors';
import ConfirmSecondaryModal from 'Containers/Common/ConfirmSecondaryModal';
import PaymentList from 'Containers/Payments/containers/PaymentList';
import { noPriceSalons } from 'Containers/Scheduler/constants';
import BlankPage from 'Containers/Payments/containers/BlankPage';
import LinearLoader from 'Containers/Common/LinearLoader';
import StepRoles from 'Containers/Payments/containers/BlankPage/StepRoles';
import StepForm from 'Containers/Payments/containers/BlankPage/StepForm'
import InformationsPage from 'Containers/Payments/containers/BlankPage/InformationsPage';
import RecoveryPwdPage from 'Containers/Auth/containers/RecoveryPwdPage';
import ResetPwdPage from 'Containers/Auth/containers/ResetPwdPage';
import Blocker from 'Containers/Scheduler/containers/Blocker';
import Modal from 'Containers/Common/Modal';
import TermsModal from 'Containers/Modals/containers/TermsModal';
import useWindowSize from 'utils/useWindowSize';
import { clientActions } from 'Containers/Clients/store';
dayjs.locale('it');
dayjs.extend(customParseFormat);
export default () => {
  const { isLoading } = useAuth();

  const isAuthenticated = useSelector(getAuth());
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const adT = searchParams.get('adT');
  const fromApp = searchParams.get('fromApp');

  const user = useSelector(getActiveSalon());
  const token = useSelector(getAuth());
  const products = useSelector(getCustomerProducts());
  const salon = useSelector(getActiveSalon());
  const [paymentsDaysLeft, setPaymentsDaysLeft] = useState(false);
  const navigate = useNavigate();
  const {isMobile} = useWindowSize();
  const [termsModal, setTermsModal] = useState(false);
  

  // setTimeout(() => {
  //   connectionSSE()
  // }, 2000)

  useEffect(() => {
    if (adT) {
      localStorage.removeItem('salons');
      dispatch(authActions.LOG_OUT.SUCCESS());
      dispatch(authActions.SIGN_IN.REQUEST({ token: adT }), () => {
        window.location.href = ROUTES.HOME;
        window.location.reload();
      });
    }
  }, [adT]);
  useEffect(() => {
    if (token){
      dispatch(settingsActions.GET_CUSTOMER_PRODUCTS.REQUEST({ clientId: user.idCostumerStripe }));
      dispatch(clientActions.GET_BAD_CLIENTS.REQUEST());
    }
    if(!localStorage.getItem('salons')) setTermsModal(false);
  }, [token]);
  useEffect(() => {
    if (products && products.giorniRimanenti <= 3) setPaymentsDaysLeft(true);
    if (products && products.acceptedTerms === false && localStorage.getItem('salons')) setTermsModal(true)
    else setTermsModal(false);
  }, [products]);
  useEffect(() => {
    if (fromApp) localStorage.setItem('fromApp', fromApp);
  }, [fromApp]);
  const isDemoPaymentAvailable =
    process.env.REACT_APP_PAYMENT_DEMO == 'true' && salon?.email == 'demo@demo.it';
  const expired = products?.giorniRimanenti && products?.giorniRimanenti <= 0;
  if (!isDemoPaymentAvailable && expired) return <PaymentsContainer />;
  const page = window.location.pathname;

  return (
    <>
      {!isLoading ? (
        <ThemeProvider theme={muiTheme}>
          <Loader />
          <Error />
          <GlobalToast />
          <ConfirmSecondaryModal
            isModalOpen={paymentsDaysLeft}
            setIsModalOpen={setPaymentsDaysLeft}
            title={
              products.giorniRimanenti > 0
                ? `Mancano ${products.giorniRimanenti} giorn${
                    products.giorniRimanenti > 1 ? 'i' : 'o'
                  } alla scadenza del tuo abbonamento`
                : 'Il tuo abbonamento scade oggi'
            }
            subtitle={
              products.giorniRimanenti > 0
                ? 'Per continuare ad utilizzare il servizio, rinnova il tuo abbonamento prima che scada'
                : 'Per continuare ad utilizzare il servizio, rinnova il tuo abbonamento'
            }
            buttons={['Vai a rinnovare', 'Chiudi']}
            Icon={CheckIcon}
            buttonsActions={[
              () => {
                navigate(ROUTES.SETTINGS + ROUTES.PAYMENTS);
                setPaymentsDaysLeft(false);
              },
              () => setPaymentsDaysLeft(false),
            ]}
          />
          <Modal 
            isModalOpen={termsModal} 
            setIsModalOpen={setTermsModal}
            fullScreen={isMobile} 
            maxHeight='fit-content'
            height='20dvh' 
            width='440px' 
          >
            <TermsModal />
          </Modal>
          <main className='container'>
            <Routes>
              <Route
                path={ROUTES.SIGN_IN}
                element={
                  <PublicRoute
                    component={SignIn}
                    isAuthenticated={isAuthenticated}
                    restricted={true}
                  />
                }
              />
              <Route
                path={`${ROUTES.SIGN_IN}/welcome`}
                element={
                  <PublicRoute
                    component={RegistrationPageContainer}
                    isAuthenticated={isAuthenticated}
                    restricted={true}
                  />
                }
              />
              <Route
                element={
                  <PageWrapper>
                    <Outlet />
                  </PageWrapper>
                }
              >
                <Route path={ROUTES.HOME} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <PrivateRoute component={HomePage} isAuthenticated={isAuthenticated} />
                    }
                  />
                </Route>
                <Route path={ROUTES.SCHEDULER} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <PrivateRoute
                        component={products.hasPricesZero && noPriceSalons.includes(salon.idsalone) ?  Blocker : SchedulerContainer}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>
                <Route path={ROUTES.PAYMENTS} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <PrivateRoute component={BlankPage} isAuthenticated={isAuthenticated} />
                    }
                  />
                  <Route
                    path={`${ROUTES.PAYMENTS}${ROUTES.INFORMATIONS}`}
                    element={
                      <PrivateRoute component={InformationsPage} isAuthenticated={isAuthenticated} />
                    }
                  />
                  <Route
                    path={`${ROUTES.PAYMENTS}${ROUTES.STEPS}`}
                    element={
                      <PrivateRoute component={StepRoles} isAuthenticated={isAuthenticated} />
                    }
                  />
                  <Route
                    path={`${ROUTES.PAYMENTS}${ROUTES.FORM}`}
                    element={
                      <PrivateRoute component={StepForm} isAuthenticated={isAuthenticated} />
                    }
                  />
                </Route>
                <Route path={ROUTES.SALON_MANAGEMENT} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <PrivateRoute
                        component={SalonManagementList}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.SERVICES}`}
                    element={
                      <PrivateRoute component={Services} isAuthenticated={isAuthenticated} />
                    }
                  />
                  <Route
                    path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}`}
                    element={<PrivateRoute component={Workers} isAuthenticated={isAuthenticated} />}
                  />
                  <Route
                    path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.SERVICES}/:workerId`}
                    element={
                      <PrivateRoute component={ServicesList} isAuthenticated={isAuthenticated} />
                    }
                  />
                  <Route
                    path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.WORKING_TIME}/:workerId`}
                    element={
                      <PrivateRoute component={WorkerWorkTime} isAuthenticated={isAuthenticated} />
                    }
                  />
                  <Route
                    path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.CLOSURES}/:workerId`}
                    element={
                      <PrivateRoute component={Closures} isAuthenticated={isAuthenticated} />
                    }
                  />
                  <Route
                    path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.EXTRA_WORKING_TIME}/:workerId`}
                    element={
                      <PrivateRoute
                        component={ExtraWorkingTime}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route
                    path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.SERVICES}${ROUTES.WORKERS}/:serviceId`}
                    element={
                      <PrivateRoute component={WorkersList} isAuthenticated={isAuthenticated} />
                    }
                  />
                  <Route
                    path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.SERVICES}${ROUTES.ICONS}/:serviceId`}
                    element={
                      <PrivateRoute
                        component={ServiceIconsList}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>

                <Route path={ROUTES.COMMUNICATIONS} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <PrivateRoute component={Communications} isAuthenticated={isAuthenticated} />
                    }
                  />
                </Route>
                <Route path={ROUTES.NOTIFICATION} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <PrivateRoute
                        component={NotificationsList}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>

                <Route path={ROUTES.STATISTICS} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <PrivateRoute
                        component={StatisticContainer}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>
                <Route path={ROUTES.SHOP} element={<Outlet />}>
                  <Route
                    index
                    element={<PrivateRoute component={Shop} isAuthenticated={isAuthenticated} />}
                  />
                </Route>
                <Route path={ROUTES.ARTICLES} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <PrivateRoute
                        component={() => <div>ARTICLES</div>}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                </Route>
                <Route
                  path={`${ROUTES.CLIENTS}`}
                  element={
                    <PrivateRoute component={ClientsList} isAuthenticated={isAuthenticated} />
                  }
                />
                <Route path={ROUTES.SETTINGS} element={<Outlet />}>
                  <Route
                    index
                    element={
                      <PrivateRoute component={SettingsList} isAuthenticated={isAuthenticated} />
                    }
                  />
                  <Route
                    path={`${ROUTES.SETTINGS}${ROUTES.SALONE}`}
                    element={
                      <PrivateRoute component={SalonInfo} isAuthenticated={isAuthenticated} />
                    }
                  />
                  <Route
                    path={`${ROUTES.SETTINGS}${ROUTES.SECURITY}`}
                    element={
                      <PrivateRoute component={Security} isAuthenticated={isAuthenticated} />
                    }
                  />
                  <Route
                    path={`${ROUTES.SETTINGS}${ROUTES.PAYMENTS}`}
                    element={
                      <PrivateRoute
                        component={PaymentsContainer}
                        isAuthenticated={isAuthenticated}
                      />
                    }
                  />
                  <Route path={`${ROUTES.SETTINGS}${ROUTES.SHARING}`} element={<Outlet />}>
                    <Route
                      index
                      element={
                        <PrivateRoute
                          component={SharingContainer}
                          isAuthenticated={isAuthenticated}
                        />
                      }
                    />
                  </Route>
                </Route>
                <Route
                  index
                  element={<PrivateRoute component={HomePage} isAuthenticated={isAuthenticated} />}
                />
              </Route>
              <Route path={ROUTES.SCHEDULER} element={<Outlet />}>
                <Route
                  index
                  element={
                    <PrivateRoute
                      component={products.hasPricesZero && noPriceSalons.includes(salon.idsalone) ?  Blocker : SchedulerContainer}
                      isAuthenticated={isAuthenticated}
                    />
                  }
                />
              </Route>
              <Route path={ROUTES.SALON_MANAGEMENT} element={<Outlet />}>
                <Route
                  index
                  element={
                    <PrivateRoute
                      component={SalonManagementList}
                      isAuthenticated={isAuthenticated}
                    />
                  }
                />
                <Route
                  path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.SERVICES}`}
                  element={<PrivateRoute component={Services} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}`}
                  element={<PrivateRoute component={Workers} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.SERVICES}/:workerId`}
                  element={
                    <PrivateRoute component={ServicesList} isAuthenticated={isAuthenticated} />
                  }
                />
                <Route
                  path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.WORKING_TIME}/:workerId`}
                  element={
                    <PrivateRoute component={WorkerWorkTime} isAuthenticated={isAuthenticated} />
                  }
                />
                <Route
                  path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.CLOSURES}/:workerId`}
                  element={<PrivateRoute component={Closures} isAuthenticated={isAuthenticated} />}
                />
                <Route
                  path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.WORKERS}${ROUTES.EXTRA_WORKING_TIME}/:workerId`}
                  element={
                    <PrivateRoute component={ExtraWorkingTime} isAuthenticated={isAuthenticated} />
                  }
                />
                <Route
                  path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.SERVICES}${ROUTES.WORKERS}/:serviceId`}
                  element={
                    <PrivateRoute component={WorkersList} isAuthenticated={isAuthenticated} />
                  }
                />
                <Route
                  path={`${ROUTES.SALON_MANAGEMENT}${ROUTES.SERVICES}${ROUTES.ICONS}/:serviceId`}
                  element={
                    <PrivateRoute component={ServiceIconsList} isAuthenticated={isAuthenticated} />
                  }
                />
              </Route>

              <Route path={ROUTES.COMMUNICATIONS} element={<Outlet />}>
                <Route
                  index
                  element={
                    <PrivateRoute component={Communications} isAuthenticated={isAuthenticated} />
                  }
                />
              </Route>
              <Route path={ROUTES.NOTIFICATION} element={<Outlet />}>
                <Route
                  index
                  element={
                    <PrivateRoute component={NotificationsList} isAuthenticated={isAuthenticated} />
                  }
                />
              </Route>

              <Route path={ROUTES.STATISTICS} element={<Outlet />}>
                <Route
                  index
                  element={
                    <PrivateRoute
                      component={StatisticContainer}
                      isAuthenticated={isAuthenticated}
                    />
                  }
                />
              </Route>
              <Route path={ROUTES.SHOP} element={<Outlet />}>
                <Route
                  index
                  element={<PrivateRoute component={Shop} isAuthenticated={isAuthenticated} />}
                />
              </Route>
              <Route path={ROUTES.ARTICLES} element={<Outlet />}>
                <Route
                  index
                  element={
                    <PrivateRoute
                      component={() => <div>ARTICLES</div>}
                      isAuthenticated={isAuthenticated}
                    />
                  }
                />
              </Route>

              <Route
                path={`${ROUTES.CLIENTS}`}
                element={<PrivateRoute component={ClientsList} isAuthenticated={isAuthenticated} />}
              />
              <Route
                path='*'
                element={
                  isAuthenticated ? <Navigate to={ROUTES.HOME} /> : <Navigate to={ROUTES.SIGN_IN} />
                }
              />
              <Route path={ROUTES.RECOVERY}  element={isAuthenticated ? <Navigate to={ROUTES.HOME} /> : <Outlet />}>
                <Route
                  index
                  element={
                    <PublicRoute
                      component={RecoveryPwdPage}
                      isAuthenticated={!isAuthenticated}
                    />
                  }
                />
                <Route
                  path={`${ROUTES.RECOVERY}/password`}
                  element={
                    isAuthenticated ? <Navigate to={ROUTES.HOME} /> 
                      : <PublicRoute
                        component={ResetPwdPage}
                        isAuthenticated={!isAuthenticated}
                      />
                  }
                />
              </Route>
            </Routes>
          </main>
          <GlobalModal />
        </ThemeProvider>
      ) : page != ROUTES.SCHEDULER ? (
        <Spinner />
      ) : (
        <LinearLoader />
      )}
    </>
  );
};
