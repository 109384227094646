import { call, put, takeLatest } from 'redux-saga/effects';

import { Api } from '../../../entries/ApiTransport';
import sagaAssessor from '../../../utils/sagaAssessor';
import { settingsActions, settingsTypes } from './actions';

import { PaymentsApi } from '../../../entries/apis/Payments.api';
import { getShopToken } from 'Containers/Shop/store/selectors';
import { getActiveSalonToken } from 'utils/salonToken';
import { authActions } from 'Containers/Auth/store';

const paymentsApi = PaymentsApi.getInstance();
const appApi = Api.getInstance();
const createCustomerPortal = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const returnUrl = location.href;
        const { clientId } = payload;
        const { data } = yield call(() =>
          paymentsApi.post('/createCustomerPortal', { returnUrl, clientId }),
        );
        yield put(settingsActions.CREATE_CUSTOMER_PORTAL.SUCCESS(data.data));
        callback?.(data);
      },
    (err) => settingsActions.CREATE_CUSTOMER_PORTAL.FAILED(err),
  );
const createPaymentPortal = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { stripeUserKey } = payload;
        const {
          data: { paymentUrl },
        } = yield call(() =>
          paymentsApi.post('/createPaymentUrl', {
            tokenApp: getActiveSalonToken(),
            stripeUserKey,
            paymentFeUrl: 'https://zb-component-payment-test.zumbat.dev/',
            zbBeUrl: 'https://zb-core-v2-prod.herokuapp.com/',
          }),
        );
        callback?.(paymentUrl);
      },
    (err) => settingsActions.CREATE_CUSTOMER_PORTAL.FAILED(err),
  );
const getCustomerBill = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { clientId } = payload;
        const { data } = yield call(() => paymentsApi.post('/customer', { clientId }));
        yield put(settingsActions.GET_CUSTOMER_BILL.SUCCESS(data));
        callback?.(data);
      },
    (err) => settingsActions.GET_CUSTOMER_BILL.FAILED(err),
  );

const getCustomerPlan = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { productIds } = payload;
        const { data } = yield call(() => paymentsApi.post('plans', { idsProducts: productIds }));
        yield put(settingsActions.GET_CUSTOMER_PLAN.SUCCESS(data));
        callback?.(data);
      },
    (err) => {
      settingsActions.GET_CUSTOMER_PLAN.FAILED(err);
    },
  );
const getCustomerProducts = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { clientId } = payload;
        const { data } = yield call(() => appApi.post('/business/auth/getDaysLeft', {}));
        
        if (data?.hasPricesZero) {
          localStorage.setItem('uf_bannerPayments', 'true');
        } else {
          localStorage.setItem('uf_bannerPayments', 'false');
        }
      
        yield put(settingsActions.GET_CUSTOMER_PRODUCTS.SUCCESS(data));
        yield put(authActions.SET_ID_COSTUMER_STRIPE.SUCCESS(data));


        callback?.(data);
      },
    (err) => {
      settingsActions.GET_CUSTOMER_PRODUCTS.FAILED(err);
    },
  );
const getCustomerInvoice = ({ payload, callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { clientId } = payload;
        const { data } = yield call(() => paymentsApi.post('/invoices', { clientId }));
        yield put(settingsActions.GET_CUSTOMER_INVOICE.SUCCESS(data));
        callback?.(data);
      },
    (err) => settingsActions.GET_CUSTOMER_INVOICE.FAILED(err),
  );
const acceptTerms = ({ callback }) =>
  sagaAssessor(
    () =>
      function* () {
        const { data } = yield call(() => appApi.post('/business/auth/setAcceptedTerms', {}));
        yield put(settingsActions.ACCEPT_TERMS.SUCCESS(data));
        callback?.();
      },
    (err) => settingsActions.ACCEPT_TERMS.FAILED(err),
  );
export default function* () {
  yield takeLatest(settingsTypes.CREATE_CUSTOMER_PORTAL.REQUEST, createCustomerPortal);
  yield takeLatest(settingsTypes.GET_CUSTOMER_BILL.REQUEST, getCustomerBill);
  yield takeLatest(settingsTypes.GET_CUSTOMER_PLAN.REQUEST, getCustomerPlan);
  yield takeLatest(settingsTypes.GET_CUSTOMER_INVOICE.REQUEST, getCustomerInvoice);
  yield takeLatest(settingsTypes.GET_CUSTOMER_PRODUCTS.REQUEST, getCustomerProducts);
  yield takeLatest(settingsTypes.CREATE_PAYMENT_PORTAL.REQUEST, createPaymentPortal);
  yield takeLatest(settingsTypes.ACCEPT_TERMS.REQUEST, acceptTerms);
}
