import styles from './SettingsList.module.scss';
import { ReactComponent as Settings } from '../../../../assets/svg/icons/impostazioni.svg';
import { ReactComponent as Payment } from '../../../../assets/svg/icons/portafogli.svg';
import { ReactComponent as Sharing } from '../../../../assets/svg/icons/condividi.svg';
import { ReactComponent as Phone } from '../../../../assets/svg/icons/chiamate.svg';
import { ReactComponent as Bin } from '../../../../assets/svg/icons/elimina.svg';
import { ReactComponent as Exit } from '../../../../assets/svg/icons/esci.svg';
import packageJson from '../../../../../package.json';
import { ReactComponent as Shop } from '../../../../assets/svg/icons/shop.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../../../routes/constants';
import { authActions } from '../../../Auth/store';
import ConfirmDeleteModal from '../../../Common/ConfirmDeleteModal';
import React from 'react';
import { useDispatch } from 'react-redux';
import ConfirmModal from '../../../Common/ConfirmModal';
import { ReactComponent as ArrowLIcon } from '../../../../assets/svg/icons/arrow_l.svg';
import useWindowSize from '../../../../utils/useWindowSize';
import clx from 'classnames';

const salonManagementCardData = [
  {
    id: 1,
    icon: <Settings />,
    title: 'Modifica Informazioni',
    description:
      'Tutte le informazioni del tuo salone, imposta un limite di prenotazioni massime effettuabili dall utente e il numero di giorni massimi prenotabili',
    link: `${ROUTES.SETTINGS}${ROUTES.SALONE}`,
    newTab: false,
  },
  {
    id: 2,
    icon: <Shop />,
    title: 'Sicurezza',
    description: 'Proteggi il tuo salone impostando vari livelli di sicurezza',
    link: `${ROUTES.SETTINGS}${ROUTES.SECURITY}`,
    newTab: false,
  },
  (() => {
    const salon = localStorage.getItem('salons');
    const salonData = salon && JSON.parse(salon);
    const salonEmail = salonData?.[0].email;
    if (salonEmail === 'demo@demo.it' && process.env.REACT_APP_PAYMENT_DEMO == 'true') return;
    return {
      id: 3,
      icon: <Payment />,
      title: 'Fatturazione',
      description: 'Gestisci i tuoi abbonamenti e le tue carte',
      link: `${ROUTES.SETTINGS}${ROUTES.PAYMENTS}`,
      newTab: false,
    };
  })(),
  {
    id: 4,
    icon: <Sharing />,
    title: 'Condivisione',
    description:
      'Condividi il link del tuo salone, Genera un Qr code o condividi semplicemente le tue poltrone dove vuoi',
    link: `${ROUTES.SETTINGS}${ROUTES.SHARING}`,
    newTab: false,
  },
  {
    id: 5,
    icon: <Phone />,
    title: 'Assistenza',
    description: 'Richiedi assistenza per qualsiasi problema',
    link: 'https://wa.me/393533357748',
    newTab: true,
  },
  {
    id: 6,
    icon: <Bin />,
    title: 'Elimina account',
    description: 'Elimina account e dati personali',
    link: `${ROUTES.SETTINGS}?delete=1`,
    newTab: false,
  },
  {
    id: 7,
    icon: <Exit />,
    title: 'Esci',
    description: 'Effettua il logout dal gestionale',
    link: `${ROUTES.SETTINGS}?logout=1`,
    newTab: false,
  },
].filter(Boolean);

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const SettingsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams({
    help: 0,
    delete: 0,
    logout: 0,
  });

  return (
    <>
      <ConfirmDeleteModal
        isModalOpen={!!+searchParams.get('delete')}
        setIsModalOpen={setSearchParams}
        title={'Sei sicuro di voler disabilitare la salone?'}
        buttons={['Annulla', 'Disabilita']}
        action={() => dispatch(authActions.DELETE_SALON.REQUEST({}, () => setSearchParams(false)))}
      />
      <ConfirmModal
        isModalOpen={!!+searchParams.get('logout')}
        setIsModalOpen={setSearchParams}
        title={"Vuoi uscire dall'account zetabarber?"}
        action={() =>
          dispatch(
            authActions.LOG_OUT.REQUEST({}, () => {
              localStorage.clear();
              window.location.href = ROUTES.HOME;
            }),
          )
        }
      />
      <div
        className={clx(styles.settings_list_container, {
          [styles.settings_list_container_mobile]: isMobile,
        })}
      >
        <div className={styles.title}>Impostazioni</div>
        <div className={styles.description}>
          Gestisci il tuo account, modifica le informazioni del salone, o imposta una parola chiave
        </div>
        <div className={styles.list_container}>
          {salonManagementCardData.map((item) => {
            return (
              <div
                onClick={() => (item.newTab ? openInNewTab(item.link) : navigate(item.link))}
                className={styles.list_item_container}
                key={item.id}
              >
                <div className={styles.info_block}>
                  {item.icon}
                  <div className={styles.description_block}>
                    <div className={styles.item_title}>{item.title}</div>
                    <div className={styles.item_description}>{item.description}</div>
                  </div>
                </div>
                <div className={styles.list_item_container_icon}>
                  <ArrowLIcon />
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.footer__version}>{packageJson.version}</div>
      </div>
    </>
  );
};

export default SettingsList;
