import { settingsTypes } from './actions';

const initialState = {
  customerPortal: '',
  bill: {},
  plans: {},
  invoices: {},
  products: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case settingsTypes.CREATE_CUSTOMER_PORTAL.SUCCESS:
      return {
        ...state,
        customerPortal: action.payload,
      };
    case settingsTypes.GET_CUSTOMER_BILL.SUCCESS:
      return {
        ...state,
        bill: action.payload,
      };
    case settingsTypes.GET_CUSTOMER_PLAN.SUCCESS:
      return {
        ...state,
        plans: action.payload,
      };
    case settingsTypes.GET_CUSTOMER_PRODUCTS.SUCCESS:
      return {
        ...state,
        products: action.payload,
      };
    case settingsTypes.GET_CUSTOMER_INVOICE.SUCCESS:
      return {
        ...state,
        invoices: action.payload,
      };
      case settingsTypes.ACCEPT_TERMS.SUCCESS:
      return {
        ...state,
        products: {...state.products, acceptedTerms: true},
      };
    default:
      return { ...state };
  }
};
